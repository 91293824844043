@import './scss/bootstrap';
@import './scss/fonts/inter';
@import './scss/fonts/material-icons';
@import './scss/colors';
@import './scss/theme';
@import '@vi-succ/design-system-web/styles/dsw.css';

* {
    box-sizing: border-box;
}

html,
body {
    height: 100%;
    padding: 0;
    margin: 0;
    font-family: 'Inter';
}

a {
    color: $color-black;
    font-weight: 600;
    text-decoration: none;
}

a,
button {
    &.mat-mdc-raised-button:hover {
        background-color: $color-primary-light;
        &.mat-primary {
            background-color: $color-primary-grey;
        }
    }
}

a,
button {
    &.mat-mdc-button,
    &.mat-mdc-outlined-button,
    &.mat-mdc-unelevated-button,
    &.mat-mdc-raised-button {
        border-radius: 160px;
        padding: 0 20px;
        white-space: nowrap;
    }
}

table {
    .mat-mdc-cell,
    .mat-mdc-header-cell {
        padding: 0;
    }
    .mat-mdc-header-cell {
        color: $color-primary-medium;
    }
}

.success-snackbar {
    background-color: $color-primary-dark;
}

.error-snackbar {
    background-color: $color-error;
}

a.link:hover {
    cursor: pointer;
}

.sticky-top {
    // lower z-index so it does not interfere with mat tooltip
    z-index: 900;
}

mat-form-field {
    &:not(.mat-form-field-disabled) {
        &.edit-field {
            * {
                cursor: pointer;
            }
        }
    }

    &.bordered-field {
        border-radius: 4px;
        border: 1px solid #d0d0d0;
        line-height: 40px;

        .mdc-line-ripple,
        .mat-mdc-form-field-subscript-wrapper,
        .mat-mdc-form-field-bottom-align::before {
            display: none !important;
        }
        .mat-mdc-form-field-infix {
            padding: 0 !important;
            min-height: auto !important;
        }
        .mat-mdc-text-field-wrapper {
            padding: 0 12px !important;
        }
        .mat-mdc-form-field-icon-suffix {
            display: flex !important;
            padding: 0 !important;
        }
        .mat-mdc-text-field-wrapper {
            .mat-mdc-form-field-flex {
                .mat-mdc-floating-label {
                    top: 50% !important;
                    font-size: 16px !important;
                    &.mdc-floating-label--float-above {
                        display: none !important;
                    }
                }
            }
        }
        .mat-mdc-select-arrow-wrapper {
            align-items: end;
        }
        .mat-mdc-icon-button {
            &.mat-mdc-button-base {
                width: 24px;
                color: $color-black;
            }
        }
    }

    mat-icon[matPrefix] {
        transform: translateY(6px);
        margin-right: 8px;
    }

    // to make a control readonly and look like "disabled" without actually disabling it, set
    // [readonly]=true on the input and
    // [class.mat-form-field-disabled]="condition" on the <mat-form-field>
    &.mat-form-field-disabled {
        input {
            color: rgba(0, 0, 0, 0.38);
        }
    }
}

.pe-none {
    pointer-events: none;
}

vi-quest-hint {
    position: absolute;
    margin: 2px 0 0 4px;
    &:hover {
        cursor: pointer;
    }
    &.shown {
        mat-icon {
            color: $color-black;
            opacity: 1;
        }
    }
    mat-icon:hover {
        color: $color-black !important;
        opacity: 1;
    }
    mat-card {
        &.mat-mdc-card {
            margin: 0 !important;
            min-width: 250px !important;
            left: auto !important;
            transform: translate(50%, 0) !important;
            box-shadow: none !important;
            z-index: 9999;
            &:hover {
                cursor: pointer;
            }
        }
    }
}

/* no arrow buttons (+/-) for <input type="number" /> */
input.mat-mdc-input-element::-webkit-outer-spin-button,
input.mat-mdc-input-element::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input.mat-mdc-input-element[type='number'] {
    -moz-appearance: textfield;
}

/* Custom Angular Material Tooltip & Questlib Hints */
.cdk-overlay-pane.mat-tooltip-panel .mat-tooltip,
vi-quest-hint mat-card.mat-mdc-card {
    padding: 8px;
    background-color: $color-white;
    border-radius: 4px;
    filter: drop-shadow(0 2px 4px rgba(17, 17, 17, 0.16));
    box-shadow: none !important;
    font-size: 12px;
    color: map-get($palette-primary, 500);
    line-height: 150%;
    &:hover {
        background-color: $color-white !important;
    }
}

// Angular 17 Changes

// Icon

mat-icon {
    overflow: unset !important;
    &.mat-error {
        color: $color-error !important;
    }
}

// Global Buttons

.mdc-button,
.mat-mdc-button {
    height: 40px !important;
    padding: 0 20px !important;
    line-height: 40px !important;
    > .mat-icon {
        margin: 0 4px 0 0 !important;
        width: 24px !important;
        height: 24px !important;
        font-size: 24px !important;
        line-height: 24px !important;
    }
}

.mat-mdc-icon-button {
    &.mat-mdc-button-base {
        --mdc-icon-button-state-layer-size: 40px !important;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 !important;
    }
    .mat-mdc-button-touch-target {
        width: 40px !important;
        height: 40px !important;
    }
    .mat-mdc-button-ripple,
    .mat-mdc-button-persistent-ripple,
    .mat-mdc-button-persistent-ripple::before {
        display: none !important;
    }
}

// Chips

.mdc-evolution-chip-set {
    .mdc-evolution-chip {
        margin-left: 0 !important;
    }
    .mdc-evolution-chip-set__chips {
        margin-left: 0 !important;
    }
}

.mat-mdc-standard-chip {
    width: 100% !important;
    &.mdc-evolution-chip--with-primary-graphic {
        .mdc-evolution-chip__graphic {
            display: none !important;
        }
    }
    .mdc-evolution-chip__action--primary {
        width: 100%;
        padding: 0 12px !important;
    }
    .mdc-evolution-chip__text-label {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        font-weight: 600;
    }
}

.mat-mdc-chip.mat-mdc-standard-chip {
    --mdc-chip-container-height: 40px !important;
}

// Form Fields

// Textfield

.mdc-form-field {
    > label {
        padding-left: 8px !important;
        font-size: 16px !important;
    }
}

.mdc-text-field {
    padding: 0 !important;
}

.mat-mdc-form-field,
.mat-mdc-select {
    font-size: 16px !important;
}

.mat-mdc-form-field-infix {
    padding: 16px 0 4px 0 !important;
    min-height: unset !important;
}

.mat-mdc-text-field-wrapper {
    .mat-mdc-form-field-flex {
        .mat-mdc-floating-label {
            top: 20px !important;
            font-size: 16px !important;
        }
    }
}

.mdc-text-field--filled {
    background: transparent !important;
    .mat-mdc-form-field-focus-overlay {
        display: none !important;
    }
    &:not(.mdc-text-field--disabled) {
        .mdc-floating-label,
        .mdc-floating-label--float-above {
            top: 20px !important;
        }
    }
    &.mdc-text-field--invalid {
        &:not(.mdc-text-field--disabled) {
            &.mdc-text-field--focused {
                .mdc-floating-label,
                .mdc-floating-label--float-above {
                    top: 20px !important;
                }
            }
        }
    }
    &.mdc-text-field--invalid {
        &:not(.mdc-text-field--disabled) {
            .mdc-floating-label,
            .mdc-floating-label--float-above {
                top: 20px !important;
            }
        }
    }
}

.mat-mdc-form-field-icon-prefix,
.mat-mdc-form-field-icon-suffix {
    > .mat-icon {
        padding: 0 !important;
    }
}

// Radio

.mat-mdc-radio-button {
    padding: 0 !important;
    .mdc-radio {
        padding: 0 !important;
    }
}

// Checkbox

.mat-mdc-list-item {
    &.mdc-list-item--with-leading-avatar,
    &.mdc-list-item--with-leading-checkbox,
    &.mdc-list-item--with-leading-icon {
        &.mdc-list-item--with-one-line {
            padding: 0 !important;
            height: auto !important;
        }
    }
}

.mdc-list-item--with-leading-checkbox {
    .mdc-list-item__start {
        margin: 0 0 0 -8px !important;
    }
}

.mdc-list-item__primary-text {
    font-size: 16px !important;
}

.mdc-list {
    margin: 0 !important;
}

.mdc-list-item--with-leading-checkbox {
    .mdc-list-item__start {
        margin: 0;
    }
}

.mat-mdc-list-option {
    .mdc-checkbox {
        padding: 8px !important;
    }
}

.mat-mdc-list-option {
    .mdc-checkbox {
        .mdc-checkbox__background {
            top: 8px !important;
            left: 8px !important;
        }
    }
}

.mdc-list-item {
    &:hover {
        &::before {
            display: none !important;
        }
    }
}

.mat-mdc-list-item-interactive {
    &::before {
        display: none !important;
    }
}

.mat-ripple-element {
    display: none !important;
}

// Skeleton

.skeleton-loader {
    margin-bottom: 0 !important;
}

// Dialog

.mat-mdc-dialog-container {
    .mdc-dialog__surface {
        padding: 24px;
    }
}

.mdc-dialog__title {
    padding: 0 0 8px 0 !important;
    font-weight: 700 !important;
    &::before {
        display: none !important;
    }
}

.mat-mdc-dialog-actions {
    padding: 0 !important;
    min-height: unset !important;
}

.mat-mdc-dialog-content {
    font-size: 16px !important;
    color: $color-black !important;
    line-height: 1.4 !important;
}

.mat-mdc-dialog-container {
    .mat-mdc-dialog-content {
        padding: 16px 0 !important;
        table {
            .mat-mdc-cell,
            .mat-mdc-header-cell {
                padding: 0 0 0 8px;
                &:first-of-type {
                    padding: 0;
                }
            }
        }
    }
}

// Paginator

.mat-mdc-paginator {
    .mat-mdc-form-field-infix {
        padding: 4px 0 !important;
        min-height: auto !important;
    }
    .mdc-text-field {
        padding: 0 16px !important;
    }
}

.mdc-text-field--outlined {
    .mdc-notched-outline {
        > * {
            border-color: map-get($palette-primary, 300) !important;
        }
    }
}

// Badge

.mat-badge-large {
    .mat-badge-content {
        display: none !important;
    }
}

// Snackbar

.mdc-snackbar__surface {
    padding: 0 !important;
}

// Customer Selection

.customer-selection {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.mat-mdc-form-field-hint-wrapper,
.mat-mdc-form-field-error-wrapper {
    background: transparent;
    z-index: 10;
}

// Global min-width 200px selector

.min-w-200 {
    min-width: 200px !important;
}
